.nav,
.navbar-nav {
  .nav-item {
    line-height: 1;

    &.dropdown {
      .dropdown-toggle {
        &:after {
          border: none;
          content: "\F140";
          font: normal normal normal 28px/1 "Material Design Icons";
          font-size: inherit;
          text-rendering: auto;
          line-height: inherit;
          vertical-align: 0;
        }
      }

      .count-indicator {
        position: relative;
        text-align: center;

        i {
          font-size: 21px;
          margin-right: 0;
          vertical-align: middle;
        }

        .count {
          position: absolute;
          left: 50%;
          width: 1rem;
          height: 1rem;
          border-radius: 100%;
          background: #FF0017;
          color: $white;
          font-size: 11px;
          top: -1px;
          font-weight: 600;
          line-height: 1rem;
          border: none;
          text-align: center;
        }

        &:after {
          display: none;
        }
      }

      i {
        margin-right: 0.5rem;
        vertical-align: middle;

        .rtl & {
          margin-left: 0.5rem;
          margin-right: 0;
        }
      }

      .navbar-dropdown {
        font-size: 0.9rem;
        margin-top: 0;
        position: absolute;
        top: calc(#{$navbar-height} - 6px);
        right: 0;
        left: auto;
        border: 1px solid rgba(182, 182, 182, 0.1);
        padding: 0 0 20px;
        min-width: 100%;
        @include border-radius(6px);
        @extend .dropdownAnimation;
        -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.13);
        -moz-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.13);
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.13);

        .rtl & {
          right: auto;
          left: 0;
        }
        overflow: hidden;
        @media (max-width: 991px) {
          right: -85px;
        }

        &.dropdown-left {
          left: 0;
          right: auto;
        }

        .badge {
          margin-left: 2.5rem;

          .rtl & {
            margin-left: 0;
            margin-right: 1.25rem;
          }
          @media (max-width:991px) {
            margin-right: 0.5rem;

            .rtl & {
              margin-left: 0.5rem;
              margin-right: 0;
            }
          }
        }

        .dropdown-item {
          @extend .d-flex;
          @extend .align-items-center;
          margin-bottom: 0;
          padding: 15px 25px;

          i {
            font-size: 17px;
          }

          .ellipsis {
            max-width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .preview-icon {
            width: 40px;
            height: 40px;
            @include display-flex;
            @include align-items(center);
            @include justify-content(center);
          }

          .small-text {
            font-size: 0.75rem;
          }
        }

        .dropdown-divider {
          margin: 0;
        }
      }
    }

    .nav-link {}
  }
}
