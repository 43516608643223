/* Dropdowns */
.dropdown-menu {
  font-size: $default-font-size;

  .dropdown-item {
    font-family: $type-2;
    letter-spacing: 0.04rem;
    font-size: 0.85rem;
    &:active {
      background: initial;
    }
  }
}
