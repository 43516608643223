/* App */

.app-heading{
  padding: $app-heading-padding-y $app-heading-padding-x;
  background: $ghost-white;
  border-bottom: solid 1px $border-color;
  h3{
    margin-bottom: 0px;
    font-size: 1.10rem;
    padding: 0.28rem 0rem;
  }
}

.app-toolbar{
  padding: $app-toolbar-padding-y $app-toolbar-padding-x;
  border-bottom: solid 1px $border-color;
  background: $gray-50;
}

.navbar.default-layout .navbar-brand-wrapper .brand-logo-mini img{
  width: auto;
  height: 40px;
  margin-top: 5px;
}

.navbar.default-layout .navbar-brand-wrapper .navbar-brand img{
  width: auto;
  height: 40px;
}

.alert{
  font-size: 0.85rem;
}

.form-group-control.required:after{
  content: '*';
  color: $red;
  display: inline-block;
  margin-left: 2px;
}

.form-group.has-danger{
  label.form-group-control{
    color: $red;
  }
}

.form-error{
  border-color: $red;
}

.card-colorful{
  background: $blue-teal-gradient;
}

.mdc-list-item__text {
  font-size: 0.90rem;
  letter-spacing: 0.70px;
}

a.mdc-list-item__text {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
a.mdc-list-item__text:hover, a.mdc-list-item__text:active, a.mdc-list-item__text:focus {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.tooltip .tooltip-inner{
  text-align: justify;
}

.input-group-text {
  font-size: 0.85rem;
}

.mdc-list.mdc-list--non-interactive .mdc-list-item {
  overflow: visible !important;
}

.mdc-list .mdc-list-item .mdc-list-item__graphic .menu-icon {
  font-size: 24px;
}

.btn.file-upload-browse {
  margin-bottom: 0px;
}

.ql-container .ql-editor {
  padding: $btn-padding-y .75rem;
  line-height: 2;
  min-height: 64px;
}

.ql-container .ql-mention-list-item {
  height: 36px;
  line-height: 36px;
  font-size: 13px;
}

.confirmation-modal {
  .confirmation-text {
    letter-spacing: 0.03rem;
    font-size: 15px;
    color: #333;
  }
}

.swal-modal {
  max-width: 320px;
  .swal-text{
    letter-spacing: 0.03rem;
    text-align: center;
    line-height: 1.6;
  }
}

.modal-content{
  background: $white;
}

.page-link {
  position: relative;
  display: block;
  padding: $pagination-padding-y $pagination-padding-x;
  margin-left: -$pagination-border-width;
  line-height: $pagination-line-height;
  color: $pagination-color;
  background-color: $pagination-bg;
  border: $pagination-border-width solid $pagination-border-color;

  &:hover {
    color: $pagination-hover-color;
    text-decoration: none;
    background-color: $pagination-hover-bg;
    border-color: $pagination-hover-border-color;
  }

  &:focus {
    z-index: 2;
    outline: 0;
    box-shadow: none;
    border: $pagination-border-width solid $pagination-border-color;
  }

}

/* I-Switch */
.i-switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 20px;
  margin: 0;
  cursor: pointer;
  background-color: #27c24c;
  border-radius: 30px;
}

.i-switch input {
  position: absolute;
  opacity: 0;
  filter: alpha(opacity=0);
}

.i-switch input:checked + i:before {
  top: 50%;
  right: 5px;
  bottom: 50%;
  left: 50%;
  border-width: 0;
  border-radius: 5px;
}

.i-switch input:checked + i:after {
  margin-left: 16px;
}

.i-switch i:before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  background-color: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 30px;
  content: "";
  -webkit-transition: all 0.2s;
          transition: all 0.2s;
}

.i-switch i:after {
  position: absolute;
  top: 1px;
  bottom: 1px;
  width: 18px;
  background-color: #fff;
  border-radius: 50%;
  content: "";
  -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
          box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  -webkit-transition: margin-left 0.3s;
          transition: margin-left 0.3s;
}

.i-switch-md {
  width: 40px;
  height: 24px;
}

.i-switch-md input:checked + i:after {
  margin-left: 17px;
}

.i-switch-md i:after {
  width: 22px;
}

.i-switch-lg {
  width: 50px;
  height: 30px;
}

.i-switch-lg input:checked + i:after {
  margin-left: 21px;
}

.i-switch-lg i:after {
  width: 28px;
}

.i-checks {
  padding-left: 20px;
  cursor: pointer;
}

.i-checks input {
  position: absolute;
  margin-left: -20px;
  opacity: 0;
}

.i-checks input:checked + i {
  border-color: #23b7e5;
}

.i-checks input:checked + i:before {
  top: 4px;
  left: 4px;
  width: 10px;
  height: 10px;
  background-color: #23b7e5;
}

.i-checks input:checked + span .active {
  display: inherit;
}

.i-checks input[type="radio"] + i,
.i-checks input[type="radio"] + i:before {
  border-radius: 50%;
}

.i-checks input[disabled] + i,
fieldset[disabled] .i-checks input + i {
  border-color: #dee5e7;
}

.i-checks input[disabled] + i:before,
fieldset[disabled] .i-checks input + i:before {
  background-color: #dee5e7;
}

.i-checks > i {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-top: -2px;
  margin-right: 4px;
  margin-left: -20px;
  line-height: 1;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #cfdadd;
}

.i-checks > i:before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  background-color: transparent;
  content: "";
  -webkit-transition: all 0.2s;
          transition: all 0.2s;
}

.i-checks > span {
  margin-left: -20px;
}

.i-checks > span .active {
  display: none;
}

.i-checks-sm input:checked + i:before {
  top: 3px;
  left: 3px;
  width: 8px;
  height: 8px;
}

.i-checks-sm > i {
  width: 16px;
  height: 16px;
  margin-right: 6px;
  margin-left: -18px;
}

.i-checks-lg input:checked + i:before {
  top: 8px;
  left: 8px;
  width: 12px;
  height: 12px;
}

.i-checks-lg > i {
  width: 30px;
  height: 30px;
}

i.mdi {
    &:before {
        vertical-align: middle;
    }
}
